import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#87b5ff',
            main: '#5383E8',
            dark: '#2251A2',
        },
        secondary: {
            light: '#fdede6',
            main: '#ff6d3f',
            dark: '#ff4922',
        },
        warning: {
            main: '#ffaf00',
            dark: '#ff9600',
        },
        error: {
            light: '#ffeff0',
            main: '#ff3b30',
            dark: '#cc2c23',
        },
        success: {
            light: '#e5f7f1',
            main: '#00c875',
            dark: '#009e5d',
        },
        background: {
            default: '#ffffff',
            placeholder: '#f4f4f4',
        },
    },
    typography: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 14,
        fontWeightLight: 300, // Work Sans
        fontWeightRegular: 400, // Work Sans
        fontWeightMedium: 700, // Roboto Condensed
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: 60,
                },
                h2: {
                    fontSize: 48,
                },
                h3: {
                    fontSize: 42,
                },
                h4: {
                    fontSize: 36,
                },
                h5: {
                    fontSize: 20,
                    fontWeight: 300,
                },
                h6: {
                    fontSize: 18,
                },
                subtitle1: {
                    fontSize: 18,
                },
                body1: {
                    fontSize: 16,
                },
                body2: {
                    fontSize: 14,
                },
            },
        },
    },
});

export default theme;
