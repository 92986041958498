import React from 'react';
import { createRoot } from 'react-dom'; // Update import statement for createRoot
import 'firebase/firestore';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

// Use createRoot instead of ReactDOM.render
createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
