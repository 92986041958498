import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import initializeFirestore from './Firestore';
import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

export const createMsalConfig = (clientId, tenantId, redirectUri) => {
    const authority = `https://login.microsoftonline.com/${tenantId}`;
    return {
        auth: {
            clientId: clientId, // Add the client ID
            authority: authority,
            redirectUri: redirectUri,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };
};

const msalConfig = createMsalConfig(
    '7676530a-5fc7-4458-adc0-9efa71dc881b',
    '7312b583-0b35-4897-99fe-e2b3c8d679ee',
    'https://accountsapp-dd7a7.firebaseapp.com'
);

const pca = new PublicClientApplication(msalConfig);

export const getSpaToken = async () => {
    try {
        const firestore = await initializeFirestore();
        const authDocRef = doc(firestore, 'appmanagement', 'auth');
        const docSnapshot = await getDoc(authDocRef);
        const authData = docSnapshot.data();

        console.log('authData:', authData);

        if (authData) {
            const { clientID, clientSecret, tenantID } = authData;

            console.log('clientID:', clientID);
            console.log('clientSecret:', clientSecret);
            console.log('tenantID:', tenantID);

            if (clientID && clientSecret && tenantID) {
                const authResponse = await axios.post(`https://login.microsoftonline.com/${tenantID}/oauth2/v2.0/token`, {
                    client_id: clientID,
                    client_secret: clientSecret,
                    scope: 'https://graph.microsoft.com/.default',
                    grant_type: 'client_credentials',
                });

                // Rest of the code...
            } else {
                console.error('Missing values in authData');
                return null;
            }
        } else {
            console.error('No authentication data found in the database');
            return null;
        }
    } catch (error) {
        console.error('Error acquiring SPA token:', error);
        return null;
    }
};
