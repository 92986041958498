import React, { useEffect, useMemo, useState } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './authConfig';
import { useAuth } from './authContext';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Grid } from '@mui/material';
import TopBar from './TopBar';
import { Stack } from '@mui/material';

const LoginPage = () => {
  const { instance, accounts } = useMsal();
  const { setToken, token } = useAuth();
  const navigate = useNavigate();
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch data logic...
    };

    fetchData();
  }, [instance]);

  const handleLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      if (response && response.account) {
        // Acquire additional access token with delegated permissions
        const delegatedLoginInstance = new PublicClientApplication(msalConfig);
        const delegatedLoginRequest = {
          ...loginRequest,
          authority: msalConfig.auth.authority,
          account: response.account,
        };
        const delegatedResponse = await delegatedLoginInstance.acquireTokenPopup(delegatedLoginRequest);

        if (delegatedResponse && delegatedResponse.accessToken) {
          setToken(delegatedResponse.accessToken); // Store delegated token in state
        }
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
    }
  };

  const handleLogout = async () => {
    if (accounts.length > 0) {
      try {
        await instance.logoutPopup({ postLogoutRedirectUri: '/' });
        setLoggedOut(true);
      } catch (error) {
        console.error('An error occurred during logout:', error);
      }
    }
  };

  useEffect(() => {
    const url = window.location.pathname;
    if (url === '/login' && accounts.length === 0) {
      handleLogin();
    }
  }, [accounts]);

  useEffect(() => {
    if (loggedOut) {
      navigate('/');
    }
  }, [loggedOut, navigate]);

  if (accounts.length > 0 && !loggedOut) {
    return (
      <>
        <TopBar onHomeClick={() => navigate('/')} /> {/* Include the TopBar component */}
        <Container>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="right" sx={{ paddingTop: '50px' }}>
            <Stack spacing={2} justifyContent="center" direction={{ xs: 'column', sm: 'row' }} alignItems="flex-end">
              <Grid item>
                <Button variant="contained" onClick={handleLogout}>Logout</Button>
                <Typography variant="h5">You are logged in</Typography>
              </Grid>
            </Stack>
          </Grid>

          <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ paddingTop: '100px' }}>
            <Stack spacing={2} justifyContent="center" direction="column" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  justifyContent="center"
                  onClick={() => navigate('/select-mailbox', { state: { token } })}
                  sx={{ width: '300px', height: '150px', fontSize: '2rem', textAlign: 'center' }}
                >
                  Mailbox Selector
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  component={Link}
                  to="/rules"
                  sx={{ width: '300px', height: '150px', fontSize: '2rem', textAlign: 'center' }}
                >
                  Rules Interface
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  component={Link}
                  to="/user-control"
                  sx={{ width: '300px', height: '150px', fontSize: '2rem', textAlign: 'center' }}
                >
                  User Access Control
                </Button>
              </Grid>
            </Stack>
          </Grid>
         
        </Container>
      </>
    );
  }

  if (loggedOut) {
    return (
      <>
        <TopBar onHomeClick={() => navigate('/')} /> {/* Include the TopBar component */}
        <Typography variant="h5">You have been logged out</Typography>
      </>
    );
  }

  return (
    <>
      <TopBar onHomeClick={() => navigate('/')} />
      {/* Code for showing login page when not logged in */}
    </>
  );
};

const App = () => {
  const pca = useMemo(() => new PublicClientApplication(msalConfig), []);

  useEffect(() => {
    const account = pca.getActiveAccount();
    if (account) {
      console.log('User is already logged in');
    }
  }, [pca]);

  return (
    <MsalProvider instance={pca}>
      <LoginPage />
    </MsalProvider>
  );
};

export default App;
