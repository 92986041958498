import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid } from '@mui/material';
import TopBar from './TopBar'; // Import the TopBar component

const MailboxSelector = () => {
    const { accounts } = useMsal();
    const navigate = useNavigate();
    const { token } = useAuth(); // Assuming you have a custom AuthContext providing the token
    const [showSettings, setShowSettings] = useState(false);

    const handleSaveSettings = () => {
        setShowSettings(false);
    };

    const handleSelect = async () => {
        try {
            if (token) {
                console.log('Token exists, navigating to mailbox view');
                // If you want to navigate to mailbox view for 'accounts@peakplan.com.au'
                navigate(`/mailbox-view/accounts@peakplan.com.au`, { state: { token, selectedUPN: 'accounts@peakplan.com.au' } });
                // Hide the settings button when navigating to the mailbox view
                setShowSettings(false);
            } else {
                console.log('Token does not exist, navigation cancelled');
            }
        } catch (error) {
            console.error('An error occurred during login:', error);
        }
    };

    return (
        <>
            <TopBar onHomeClick={() => navigate('/')} /> {/* Include the TopBar component */}
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '100px' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
                    <form onSubmit={(event) => event.preventDefault()}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={handleSelect}
                                sx={{ marginTop: '10px', width: '400px' }}
                            >
                                Go to accounts@peakplan.com.au
                            </Button>
                        </Grid>
                       
                        
                    </form>
                </Grid>
            </Container>
            
                
        </>
    );
};

export default MailboxSelector;
