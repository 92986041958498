import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Client, getAttachments } from '@microsoft/microsoft-graph-client';
import { useAuth } from './authContext';
import './MailboxView.css';
import { ResizableBox } from 'react-resizable';
import { Letter } from 'react-letter';
import { extract } from 'letterparser';
import { DataGrid, GridColumns, GridColDef, GridCellParams } from '@mui/x-data-grid';
import {
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    Paper,
    Box,
    LinearProgress,
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Collapse,
    CssBaseline,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    Divider,
    Menu,
    MenuItem as MuiMenuItem,
    ButtonGroup,
    Switch, 
    FormGroup,

} from '@mui/material';
import {
    DragHandle,
    DragIndicator,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Star,
    Label,
    Menu as MenuIcon,
    Home as HomeIcon,
    Close,
    ArrowBack,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Flag as FlagIcon,
    FileDownload,
} from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import { Avatar } from '@mui/material';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';


import SearchPopover from './SearchPopover';
import ErrorBoundary from './ErrorBoundary';

const MailboxView = () => {
    const [folders, setFolders] = useState([]);
    const [messages, setMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMiniVariant, setIsMiniVariant] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(300);
    const [listWidth, setListWidth] = useState(900);
    const [previewWidth, setPreviewWidth] = useState(300); // Updated width for the preview
    const [searchValue, setSearchValue] = useState('');
    const [searchFolder, setSearchFolder] = useState('All');
    const { upn } = useParams();
    const { token } = useAuth();
    const navigate = useNavigate();
    const [favoriteFolders, setFavoriteFolders] = useState(['']);
    const [selectedFolderName, setSelectedFolderName] = useState('');
    const [profilePictureUrl, setProfilePictureUrl] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [checked, setChecked] = useState(false);
    const [mode, setMode] = useState('Normal');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedBulkAction, setSelectedBulkAction] = useState('');
    const [matchType, setMatchType] = useState('contains'); 
    const [filterOptions, setFilterOptions] = useState({
        matchSender: false,
        matchSubject: false,
        excludeInlineAttachments: false,
        inlineAttachmentsOnly: false,
        pdfOnly: false,
        otherAttachments: false,
        noAttachments: false,
        linkInBody: false,

        // Add more filter options as needed
    });


    const fetchUserProfile = useCallback(async () => {
        try {
            const client = getClient(token);
            const user = await client.api('/me').get();
            const { givenName, surname } = user;
            setFirstName(givenName);
            setLastName(surname);
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    }, [token]);

    const fetchFolders = useCallback(async () => {
        try {
            const client = getClient(token);
            const response = await client.api(`/users/${upn}/mailFolders?$top=1000`).get();
            setFolders(response.value);


        } catch (error) {
            console.error('Error fetching mail folders:', error);
        }
    }, [upn, token]);

    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile]);

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const client = getClient(token);
                const response = await client.api(`/users/${upn}/mailFolders?$top=1000`).get();
                setFolders(response.value);
                const inboxFolder = response.value.find((folder) => folder.displayName === 'Inbox');
                if (inboxFolder) {
                    setSelectedFolderName('Inbox');
                    handleFolderClick(inboxFolder.id);
                }
            } catch (error) {
                console.error('Error fetching mail folders:', error);
                navigate('/select-mailbox');
            }
        };

        fetchFolders();
    }, [upn, token]);

    useEffect(() => {
        const filteredMessages = messages.map((message) => {
            const attachmentCount = message.attachments.filter((attachment) => !attachment.isInline).length;
            const inlineAttachments = message.attachments.filter((attachment) => attachment.isInline);

            return {
                ...message,
                attachmentCount,
                inlineAttachments,
            };
        }).filter((message) => {
            // Apply search filter
            const containsSearchValue =
                !searchValue || // Check if search value is empty
                message.from.emailAddress.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                message.subject.toLowerCase().includes(searchValue.toLowerCase()) ||
                message.body.content.toLowerCase().includes(searchValue.toLowerCase());

            // Apply additional filter options
            const hasInlineAttachments = message.inlineAttachments.length > 0;
            const hasAttachments = message.attachments.length > 0;
            const isPdfOnly = hasAttachments && message.attachments.some((attachment) => attachment.contentType === 'application/pdf');
            const hasOtherAttachments = message.attachments.some((attachment) => {
                const attachmentType = attachment.contentType.toLowerCase();
                return !attachment.isInline && attachmentType !== 'application/pdf';
            });
            const hasNoAttachments = message.attachments.length === 0;
            const hasLinkInBody = message.body.content.toLowerCase().includes('http://') || message.body.content.toLowerCase().includes('https://');

            return (
                containsSearchValue &&
                (!filterOptions.excludeInlineAttachments || !hasInlineAttachments) &&
                (!filterOptions.inlineAttachmentsOnly || hasInlineAttachments) &&
                (!filterOptions.pdfOnly || isPdfOnly) &&
                (!filterOptions.otherAttachments || hasOtherAttachments) &&
                (!filterOptions.noAttachments || hasNoAttachments) &&
                (!filterOptions.linkInBody || hasLinkInBody)
                // Add more conditions for additional filter options
            );
        });

        setFilteredMessages(filteredMessages);
    }, [searchValue, messages, filterOptions]);





    const getClient = (token) => {
        const clientOptions = {
            authProvider: {
                getAccessToken: async () => {
                    return token;
                },
            },
        };

        return Client.initWithMiddleware(clientOptions);
    };

    const handleMatchTypeChange = (event) => {
        setMatchType(event.target.value);
    };

    const handleFolderClick = async (folderId) => {
        if (!token) {
            console.error('User is not authenticated');
            return;
        }

        try {
            const client = getClient(token);
            const folder = folders.find((folder) => folder.id === folderId);
            if (folder) {
                setSelectedFolderName(folder.displayName);
            }
            let endpoint = `/users/${upn}/mailFolders/${folderId}/messages?$expand=attachments&$top=50`;
            if (searchFolder !== 'All') {
                endpoint = `/users/${upn}/mailFolders/${searchFolder}/childFolders/${folderId}/messages?$expand=attachments&$top=50`;
            }
            console.log(`Fetching messages from: ${endpoint}`);
            const response = await client.api(endpoint).get();

            const messagesWithAttachments = await Promise.all(response.value.map(async (message) => {
                const attachmentCount = message.attachments ? message.attachments.length : 0;
                const inlineAttachments = [];
                const normalAttachments = [];

                if (attachmentCount > 0) {
                    for (const attachment of message.attachments) {
                        if (attachment.isInline) {
                            inlineAttachments.push(attachment);
                        } else {
                            normalAttachments.push(attachment);
                        }
                    }
                }

                return {
                    ...message,
                    attachmentCount,
                    inlineAttachments,
                    normalAttachments,
                };
            }));

            setMessages(messagesWithAttachments);
            setSelectedMessage(null);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };


    const handleMessageClick = (message) => {
        setSelectedMessage(message);
    };

    const handleSidebarResize = (event, { size }) => {
        if (size.width <= 50) {
            setIsMiniVariant(true);
        } else {
            setIsMiniVariant(false);
        }
        setSidebarWidth(size.width);
    };

    const handleListResize = (event, { size }) => {
        setListWidth(size.width);
    };

    const handleActionButtonClick = (event, row) => {
        // Handle the button click event
        let actionIcon;

        if (mode === 'AttachmentExtractor') {
            actionIcon = <FileDownload />;
        } else if (mode === 'MailSend') {
            actionIcon = <SendIcon />;
        } else if (mode === 'Bulk') {
            actionIcon = <FlagIcon />;
        } else {
            actionIcon = <FlagIcon />;
        }

        console.log('Action button clicked!', row, actionIcon);
    };

    const handlePreviewResize = (event, { size }) => {
        setPreviewWidth(size.width);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleFilterOptionChange = (event) => {
        const { name, checked } = event.target;
        setFilterOptions((prevOptions) => ({
            ...prevOptions,
            [name]: checked,
        }));
    };


    const handleSearchFolderChange = (event) => {
        setSearchFolder(event.target.value);
    };
    const handleChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setMode(name);
            setSelectedBulkAction(''); // Reset the selected bulk action
        } else {
            setMode('Normal'); // Switch back to 'Normal' mode when the switch is unchecked
        }
    };

    const handleSearchSubmit = async (searchValue, matchType) => {
        if (!token) {
            console.error('User is not authenticated');
            return;
        }

        try {
            const client = getClient(token);
            let endpoint = `/users/${upn}/messages?$top=50`;

            if (searchValue) {
                let filterExpression = '';

                switch (matchType) {
                    case 'contains':
                        filterExpression = `contains(subject,'${searchValue}') or contains(body/content,'${searchValue}') or contains(from/emailAddress/name,'${searchValue}')`;
                        break;
                    case 'exact':
                        // Wrap the search value in single quotes for an exact match
                        filterExpression = `subject eq '${searchValue}' or body/content eq '${searchValue}' or from/emailAddress/name eq '${searchValue}'`;
                        break;
                    case 'startsWith':
                        filterExpression = `startswith(subject,'${searchValue}') or startswith(body/content,'${searchValue}') or startswith(from/emailAddress/name,'${searchValue}')`;
                        break;
                    case 'endsWith':
                        filterExpression = `endswith(subject,'${searchValue}') or endswith(body/content,'${searchValue}') or endswith(from/emailAddress/name,'${searchValue}')`;
                        break;
                    default:
                        break;
                }

                endpoint += `&$filter=${filterExpression}`;
            }

            console.log(`Fetching search results from: ${endpoint}`);
            const response = await client.api(endpoint).get();
            setMessages(response.value);
            setSelectedMessage(null);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const rewriteExternalResources = (url) => {
        // Custom logic to rewrite the URLs for external resources
        // Example: prepend a base URL or modify the URL as needed
        return url;
    };

    const rewriteExternalLinks = (url) => {
        // Custom logic to rewrite the URLs for external links
        // Example: prepend a base URL or modify the URL as needed
        return url;
    };

    const renderFolder = (folder) => {
        const isFolderOpen = Boolean(selectedMessage && selectedMessage.id === folder.id);
        const isFavorite = favoriteFolders.some((favFolder) => favFolder.id === folder.id);

        const handleRemoveFromFavorites = (event, folderId) => {
            event.stopPropagation(); // Stop event propagation to prevent the folder from collapsing
            setFavoriteFolders((prevFolders) => prevFolders.filter((favFolder) => favFolder.id !== folderId));
        };

        const isChildFolder = Boolean(folder.parentFolderId);

        if (isChildFolder) {
            return (
                <ListItemButton
                    key={folder.id}
                    onClick={(event) => handleFolderClick(folder.id)}
                    onContextMenu={(event) => handleContextMenuOpen(event, folder)}
                >
                    <ListItemIcon>
                        {isFavorite ? (
                            <Close onClick={(event) => handleRemoveFromFavorites(event, folder.id)} />
                        ) : (
                            <Star />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={folder.displayName} />
                </ListItemButton>
            );
        }

        return (
            <React.Fragment key={folder.id}>
                <ListItemButton
                    onClick={(event) => handleFolderClick(folder.id)}
                    onContextMenu={(event) => handleContextMenuOpen(event, folder)}
                >
                    <ListItemIcon>
                        {isFavorite ? (
                            <Close onClick={(event) => handleRemoveFromFavorites(event, folder.id)} />
                        ) : (
                            <Star />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={folder.displayName} />
                </ListItemButton>
                <Collapse in={isFolderOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {folder.childFolders &&
                            folder.childFolders.length > 0 &&
                            folder.childFolders.map((childFolder) => renderFolder(childFolder))}
                    </List>
                </Collapse>
            </React.Fragment>
        );
    };

    const initialColumns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.1,
            maxwidth: 50,
            minWidth: 50,
            align: 'center',
            renderCell: (params) => (
                <React.Fragment>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedMessage === params.row}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleMessageClick(params.row);
                                }}
                            />
                        }
                        label=""
                    />
                </React.Fragment>
            ),
        },
        {
            field: 'dateTime',
            headerName: 'DateTime',
            flex: 0.4,
            maxwidth: 200,
            minWidth: 10,
            valueGetter: (params) => new Date(params.row.receivedDateTime).toLocaleString(),
        },
        {
            field: 'from',
            headerName: 'Sender',
            flex: 0.4,
            maxwidth: 400,
            minWidth: 100,
            valueGetter: (params) => params.row.from.emailAddress.name,
        },
        {
            field: 'subject',
            headerName: 'Subject',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'attachmentCount',
            headerName: 'Attachments',
            flex: 0.15,
            maxwidth: 400,
            minWidth: 50,
            align: 'center',
            valueGetter: (params) => params.row.attachmentCount || 0,
        },
        {
            field: 'inlineAttachments',
            headerName: 'Inline Attachments',
            flex: 0.2,
            maxwidth: 400,
            minWidth: 50,
            align: 'center',
            valueGetter: (params) => params.row.inlineAttachments.length || 0,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 0.4,
            maxwidth: 100,
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                let actionIcon;

                if (mode === 'AttachmentExtractor') {
                    actionIcon = <FileDownload />;
                } else if (mode === 'MailSend') {
                    actionIcon = <SendIcon />;
                } else {
                    actionIcon = <FlagIcon />;
                }

                return (
                    <IconButton
                        onClick={(event) => handleActionButtonClick(event, params.row)}
                        size="small"
                        color="red"
                    >
                        {actionIcon}
                    </IconButton>
                );
            },
        },
    ];

    const [columns, setColumns] = useState(initialColumns);

    useEffect(() => {
        const updatedColumns = [...initialColumns];
        const actionColumn = updatedColumns.find((column) => column.field === 'action');

        if (mode === 'AttachmentExtractor') {
            actionColumn.renderCell = (params) => (
                <IconButton onClick={(event) => handleActionButtonClick(event, params.row)} size="small" color="red">
                    <FileDownload />
                </IconButton>
            );
        } else if (mode === 'MailSend') {
            actionColumn.renderCell = (params) => (
                <IconButton onClick={(event) => handleActionButtonClick(event, params.row)} size="small" color="red">
                    <SendIcon />
                </IconButton>
            );
        } else {
            actionColumn.renderCell = (params) => (
                <IconButton onClick={(event) => handleActionButtonClick(event, params.row)} size="small" color="red">
                    <FlagIcon />
                </IconButton>
            );
        }

        setColumns(updatedColumns);
    }, [mode]);

    const [contextMenuParams, setContextMenuParams] = useState(null);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

    const handleContextMenuOpen = (event, row) => {
        event.preventDefault();
        event.stopPropagation(); // Stop event propagation to prevent the context menu from closing
        setContextMenuParams({ row, mouseEvent: event });
        setIsContextMenuOpen(true);
    };

    const handleContextMenuClose = () => {
        setContextMenuParams(null);
        setIsContextMenuOpen(false);
    };

    useEffect(() => {
        const handleWindowContextMenu = (event) => {
            if (isContextMenuOpen) {
                handleContextMenuClose();
            }
        };

        window.addEventListener('contextmenu', handleWindowContextMenu);

        return () => {
            window.removeEventListener('contextmenu', handleWindowContextMenu);
        };
    }, [isContextMenuOpen]);

    const handleContextMenuAction = (action) => {
        const { row } = contextMenuParams;
        switch (action) {
            case 'favorite':
                console.log(`Add folder to Favorites: ${row.displayName}`);
                if (!favoriteFolders.some((favFolder) => favFolder.id === row.id)) {
                    setFavoriteFolders((prevFolders) => [...prevFolders, row]);
                }
                break;
            default:
                break;
        }
        handleContextMenuClose();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CssBaseline />
            <AppBar position="fixed" open={isDrawerOpen}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        {isDrawerOpen ? <Close /> : <MenuIcon />}
                    </IconButton>
                    <div style={{ flexGrow: 1, display: 'flex', alignItems: 'left' }}>
                        <Typography variant="h6" noWrap component="div">
                            {selectedFolderName ? selectedFolderName : `Inbox`}
                        </Typography>
                    </div>
                    

                    
                    <Toolbar>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid black',
                                borderRadius: '4px',
                                padding: '8px',
                            }}
                        >
                            
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={mode === 'Normal'}
                                        onChange={handleChange}
                                        name="Normal"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="small"
                                    />
                                }
                                label="Normal Mode"
                                sx={{ marginBottom: '4px' }}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={mode === 'AttachmentExtractor'}
                                        onChange={handleChange}
                                        name="AttachmentExtractor"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="small"
                                    />
                                }
                                label="Attachment Extractor Mode"
                                sx={{ marginBottom: '4px' }}
                            />
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={mode === 'Bulk'}
                                            onChange={handleChange}
                                            name="Bulk"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="success"
                                            size="small"
                                        />
                                    }
                                    label="Bulk Mode"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={mode === 'MailSend'}
                                            onChange={handleChange}
                                            name="MailSend"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="success"
                                            size="small"
                                        />
                                    }
                                    label="Mail Send Mode"
                                />
                                {mode === 'MailSend' && (
                                    <FormControl sx={{ marginLeft: '16px', minWidth: '300px' }}>
                                        <InputLabel sx={{ position: 'relative', top: '0px', marginBottom: '-10px' }}>
                                            Send To Address
                                        </InputLabel>
                                        <Select
                                            value={selectedEmail}
                                            onChange={(event) => setSelectedEmail(event.target.value)}
                                            sx={{ height: '30px' }}
                                        >
                                            <MenuItem value="csm@peakplan.com.au">csm@peakplan.com.au</MenuItem>
                                            <MenuItem value="sa@peakplan.com.au">sa@peakplan.com.au</MenuItem>
                                            <MenuItem value="payme@peakplan.com.au">payme@peakplan.com.au</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                                {mode === 'Bulk' && (
                                    <FormControl sx={{ marginLeft: '16px', minWidth: '300px' }}>
                                        <InputLabel sx={{ position: 'relative', top: '0px', marginBottom: '-10px' }}>
                                            Bulk Action
                                        </InputLabel>
                                        <Select
                                            value={selectedBulkAction}
                                            onChange={(event) => setSelectedBulkAction(event.target.value)}
                                            sx={{ height: '30px' }}
                                        >
                                            <MenuItem value="action1">Save All PDF Attachments</MenuItem>
                                            <MenuItem value="action2">Smart A.I Save All Attachments </MenuItem>
                                            <MenuItem value="action3">Save All Invoice Attachments</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </FormGroup>
                        </Box>
                    </Toolbar>
                    
                   

                    <div style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <IconButton
                            color="inherit"
                            aria-label="back"
                            onClick={() => navigate('/select-mailbox')}
                            edge="start"
                            sx={{
                                marginRight: 2,
                            }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="home"
                            onClick={() => navigate('/login')}
                            edge="start"
                            sx={{
                                marginRight: 2,
                            }}
                        >
                            <HomeIcon />
                        </IconButton>
                        <div

                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 2,
                            }}
                        >
                            <Stack direction="row" spacing={2}>

                                <Avatar
                                    sx={{ bgcolor: deepOrange[500] }}
                                    alt={`${firstName ? firstName.charAt(0) : ''}${lastName ? lastName.charAt(0) : ''}`}
                                    src={""}
                                />

                            </Stack>
                        </div>
                    </div>
                </Toolbar>
                
                
            </AppBar>

            <Drawer
                variant={isMiniVariant ? 'permanent' : 'temporary'}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            >
                <Toolbar />
                <Toolbar position="fixed" sx={{ top: 0, left: 0 }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        edge="start"
                        sx={{
                            marginRight: 20,
                            marginBottom: 15,
                        }}
                    >
                        {isDrawerOpen ? <MenuIcon /> : <MenuIcon />}
                    </IconButton>
                    <div style={{ position: 'absolute', left: 0, paddingLeft: 20 }}>
                        <b>
                            <p>Mailbox:</p>
                        </b>
                        <p>{upn}</p>
                    </div>
                </Toolbar>

                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                            sx={{
                                justifyContent: isSidebarOpen ? 'initial' : 'left',
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 0 }}>
                                <Star />
                            </ListItemIcon>
                            <Typography variant="h7" component="div">
                                Favorites
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    {favoriteFolders.map((folder) => (
                        <ListItem key={folder.id} disablePadding>
                            <ListItemButton
                                onClick={() => handleFolderClick(folder.id)}
                                onContextMenu={(event) => handleContextMenuOpen(event, folder)}
                            >
                                <ListItemText primary={folder.displayName} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemText>
                            <h3>Folders</h3>
                        </ListItemText>
                    </ListItem>
                    {folders.map((folder) => renderFolder(folder))}
                </List>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 4 }}>
                <Toolbar />

                <SearchPopover
                    onSearchSubmit={handleSearchSubmit}
                    folders={folders}
                    searchValue={searchValue}
                    searchFolder={searchFolder}
                    onSearchChange={handleSearchChange}
                    onSearchFolderChange={handleSearchFolderChange}
                    filterOptions={filterOptions}
                    onFilterOptionChange={handleFilterOptionChange}
                    matchType={matchType} // Pass the matchType state to the SearchPopover component
                    onMatchTypeChange={handleMatchTypeChange} // Add the event handler for match type change
                />
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="matchSender"
                                    checked={filterOptions.matchSender}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="Match Sender"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="matchSubject"
                                    checked={filterOptions.matchSubject}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="Match Subject"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="excludeInlineAttachments"
                                    checked={filterOptions.excludeInlineAttachments}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="Exclude Inline Attachments"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="inlineAttachmentsOnly"
                                    checked={filterOptions.inlineAttachmentsOnly}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="Inline Attachments Only"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="pdfOnly"
                                    checked={filterOptions.pdfOnly}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="PDF Only"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="otherAttachments"
                                    checked={filterOptions.otherAttachments}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="Other Attachments"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="noAttachments"
                                    checked={filterOptions.noAttachments}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="No Attachments"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="linkInBody"
                                    checked={filterOptions.linkInBody}
                                    onChange={handleFilterOptionChange}
                                />
                            }
                            label="Link in Body"
                        />
                    </Grid>
                    {/* Add more checkboxes for additional filter options */}
                </Grid>


                <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <ResizableBox
        className="message-list"
        width={listWidth}
        height={NaN}
        axis="x"
        onResize={handleListResize}
        style={{ minWidth: 0 }}
        maxwidth={600}
      >
        <Paper sx={{ padding: '10px', height: '100%' }}>
          <div>
            {filteredMessages.length === 0 ? (
              <LinearProgress />
            ) : (
              <DataGrid
                rows={filteredMessages.slice()}
                columns={columns}
                autoHeight
                rowHeight={50}
                checkboxSelection={false}
                onRowClick={(params) => {
                  handleMessageClick(params.row);
                }}
                getRowClassName={(params) => (params.row === selectedMessage ? 'selected' : '')}
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableExtendRowFullWidth
                disableReorderColumns={false}
                components={{
                  Toolbar: () => null,
                  ColumnHeaderDraggable: (props) => <DragHandle {...props} />,
                  ColumnResizer: (props) => <DragIndicator {...props} />,
                }}
                onContextMenu={(event, params) => handleContextMenuOpen(event, params)}
              />
            )}
          </div>
        </Paper>
      </ResizableBox>
                    {selectedMessage && (
                        <ResizableBox
                            className="message-preview"
                            width={previewWidth}
                            height={NaN}
                            axis="x"
                            onResize={handlePreviewResize}
                            style={{ minWidth: 0 }}
                        >
                            <Paper sx={{ padding: '10px', height: '100%' }}>
                                <div>
                                    <h3>Details</h3>
                                    <Letter
                                        html={selectedMessage.body.content}
                                        text={selectedMessage.bodyPreview}
                                        useIframe={false}
                                        iframeTitle={selectedMessage.subject}
                                        rewriteExternalResources={rewriteExternalResources}
                                        rewriteExternalLinks={rewriteExternalLinks}
                                        allowedSchemas={['http', 'https', 'mailto']}
                                        preserveCssPriority={true}
                                        className="email-wrapper"
                                    />
                                </div>
                            </Paper>
                        </ResizableBox>
                    )}
                </Box>
            </Box>
            {contextMenuParams && contextMenuParams.mouseEvent && (
                <Menu
                    open={isContextMenuOpen}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenuParams.mouseEvent
                            ? { top: contextMenuParams.mouseEvent.pageY, left: contextMenuParams.mouseEvent.pageX }
                            : undefined
                    }
                >
                    <MuiMenuItem onClick={() => handleContextMenuAction('favorite')}>Add to Favorites</MuiMenuItem>
                </Menu>
            )}
        </Box>
    );
};

export default MailboxView;