import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import initializeFirestore from './Firestore';

const TestPage = () => {
    const [todo, setTodo] = useState('');
    const [todos, setTodos] = useState([]);

    useEffect(() => {
        const fetchTodos = async () => {
            try {
                const db = await initializeFirestore();
                const querySnapshot = await getDocs(collection(db, 'todos'));
                const todosData = querySnapshot.docs.map((doc) => doc.data());
                setTodos(todosData);
            } catch (error) {
                console.error('Error fetching todos: ', error);
            }
        };

        fetchTodos();
    }, []);

    const addTodo = async (e) => {
        e.preventDefault();

        try {
            const db = await initializeFirestore();
            const docRef = await addDoc(collection(db, 'todos'), {
                todo: todo,
            });
            console.log('Document written with ID: ', docRef.id);
            setTodo('');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <div>
            <h1>Test Page</h1>
            <form onSubmit={addTodo}>
                <input
                    type="text"
                    placeholder="Enter a todo"
                    value={todo}
                    onChange={(e) => setTodo(e.target.value)}
                />
                <button type="submit">Add Todo</button>
            </form>
            <ul>
                {todos.map((todo, index) => (
                    <li key={index}>{todo.todo}</li>
                ))}
            </ul>
        </div>
    );
};

export default TestPage;
