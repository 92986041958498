import React, { useEffect, useState } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { AuthProvider } from './authContext';
import AppRouter from './AppRouter';

function App() {
    const { instance } = useMsal();
    const [firebaseInitialized, setFirebaseInitialized] = useState(true); // Set to true since Firebase is not being used
    const [firestore, setFirestore] = useState(null); // Remove Firebase-related state
    const [auth, setAuth] = useState(null); // Remove Firebase-related state

    useEffect(() => {
        // Remove Firebase-related logic
    }, []);

    useEffect(() => {
        if (firebaseInitialized) {
            const fetchData = async () => {
                const userAccount = instance.getActiveAccount();
                if (userAccount) {
                    // Remove Firebase-related logic
                }
            };

            fetchData();
        }
    }, [firebaseInitialized, instance]);

    const msalInstance = new PublicClientApplication({
        auth: {
            clientId: msalConfig.auth.clientId,
            authority: msalConfig.auth.authority,
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: msalConfig.cache.cacheLocation,
            storeAuthStateInCookie: msalConfig.cache.storeAuthStateInCookie,
        },
    });

    msalInstance.setActiveAccount(instance.getActiveAccount());

    if (!firebaseInitialized) {
        return <div>Loading...</div>;
    }

    return (
        <MsalProvider instance={msalInstance}>
            <AuthProvider>
                <AppRouter />
            </AuthProvider>
        </MsalProvider>
    );
}

export default App;
