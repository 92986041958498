import { LogLevel } from '@azure/msal-browser';

export const createMsalConfig = (clientId, tenantId, redirectUri) => {
    const authority = `https://login.microsoftonline.com/${tenantId}`;
    return {
        auth: {
            clientId: 'ea01ce78-16c7-4b38-a228-10f4bac3b3e5',
            authority: 'https://login.microsoftonline.com/5a7996b9-71d8-4ce0-9312-e38355ce7a9d',
            redirectUri: 'https://accountsapp.peakplan.au/',
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };
};

export const loginRequest = {
    scopes: [
        'openid',
        'profile',
        'User.Read',
        'Mail.Read.Shared',
        'Mail.Read',
        'Mail.ReadBasic.Shared',
        'Files.ReadWrite.All',
        'MailboxSettings.Read',
        'MailboxSettings.ReadWrite',
    ],
};

export const msalConfig = createMsalConfig(
    '7676530a-5fc7-4458-adc0-9efa71dc881b',
    '7312b583-0b35-4897-99fe-e2b3c8d679ee',
    'https://accountsapp-dd7a7.firebaseapp.com'
);


