import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Home } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom'; // Import the useNavigate hook

const TopBar = () => {
    const navigate = useNavigate(); // Use the useNavigate hook

    const handleHomeClick = () => {
        navigate('/login'); // Navigate to the LoginPage
    };

    return (
        <AppBar position="static" color="primary">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    MailexMax
                </Typography>
                <IconButton edge="end" color="inherit" aria-label="home" onClick={handleHomeClick}>
                    <Home />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;