import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import initializeFirestore from './Firestore';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const AppTokenSettings = () => {
    const [clientID, setClientID] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [tenantID, setTenantID] = useState(''); // Add the Tenant ID state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const firestore = await initializeFirestore();
                const authDocRef = doc(firestore, 'appmanagement', 'auth');
                const docSnapshot = await getDoc(authDocRef);
                const authData = docSnapshot.data();

                if (authData) {
                    setClientID(authData.clientID || '');
                    setClientSecret(authData.clientSecret || '');
                    setTenantID(authData.tenantID || ''); // Set the Tenant ID state
                }
            } catch (error) {
                console.error('Failed to fetch authentication details:', error);
            }
        };

        fetchData();
    }, []);

    const handleSaveAuthDetails = async () => {
        try {
            const firestore = await initializeFirestore();
            const authDocRef = doc(firestore, 'appmanagement', 'auth');
            await updateDoc(authDocRef, {
                clientID,
                clientSecret,
                tenantID, // Replace 'accessToken' with 'tenantID'
            });
        } catch (error) {
            console.error('Failed to save authentication details:', error);
        }
    };

    const handleReturn = () => {
        navigate('/rules');
    };

    const maskClientSecret = (value) => {
        const maskedValue = '*'.repeat(value.length);
        return maskedValue;
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                App Token Settings
            </Typography>

            {/* Client ID */}
            <div style={{ marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Client ID
                </Typography>
                <TextField
                    label="Client ID"
                    value={clientID}
                    onChange={(e) => setClientID(e.target.value)}
                    fullWidth
                />
            </div>

            {/* Client Secret */}
            <div style={{ marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Client Secret
                </Typography>
                <TextField
                    label="Client Secret"
                    value={maskClientSecret(clientSecret)}
                    onChange={(e) => setClientSecret(e.target.value)}
                    fullWidth
                />
            </div>

            {/* Tenant ID */}
            <div style={{ marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Tenant ID
                </Typography>
                <TextField
                    label="Tenant ID"
                    value={tenantID}
                    onChange={(e) => setTenantID(e.target.value)}
                    fullWidth
                />
            </div>

            <Button variant="contained" onClick={handleSaveAuthDetails} sx={{ marginRight: '10px' }}>
                Save Authentication Details
            </Button>
            <Button variant="contained" onClick={handleReturn}>
                Return to Rules Interface
            </Button>
        </Container>
    );
};

export default AppTokenSettings;
