import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider } from './authContext';
import LoginPage from './LoginPage';
import MailboxSelector from './MailboxSelector';
import RulesInterface from './RulesInterface';
import UserAccessControl from './UserAccessControl';
import AppSettingsPage from './AppSettingsPage';
import MailboxView from './MailboxView';
import TestPage from './TestPage';
import Home from './frontpage/Blog';
import AppTokenSettings from './appTokenSettings'; // Update the import

const AppRouter = () => {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/select-mailbox" element={<MailboxSelector />} />
                    <Route path="/rules" element={<RulesInterface />} />
                    <Route path="/user-control" element={<UserAccessControl />} />
                    <Route path="/settings" element={<AppSettingsPage />} />
                    <Route path="/mailbox-view/:upn" element={<MailboxView />} />
                    <Route path="/test" element={<TestPage />} />
                    <Route path="/app-token-settings" element={<AppTokenSettings />} /> {/* Update the route path */}
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default AppRouter;