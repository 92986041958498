import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import Footer from './Footer';
import post1 from './blog-post.1.md';
import post2 from './blog-post.2.md';
import post3 from './blog-post.3.md';
import Button from '@mui/material/Button';
import theme from '../theme'; // Import the theme from the correct location
import Alert from '@mui/material/Alert'; // Import the Alert component
import Stack from '@mui/material/Stack';

const sections = [
  { title: 'Participant Portal', url: '#' },
  { title: 'Upload Invoice', url: '#' },
  { title: 'NDIS Invoice Template', url: '#' },
  { title: 'Provider Portal', url: '#' },
];

const mainFeaturedPost = {
  title: 'Mockup Accounts GUI',
  description: 'Most functions not yet implemented, only basic mailbox views etc.',
};

const posts = [post1, post2, post3];

export default function Blog() {
  const [showAlert, setShowAlert] = useState(true);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header
          title="Mockup Mailbox GUI"
          sections={sections.map((section) => ({
            ...section,
            component: Button,
            variant: 'outlined',
            size: 'small',
          }))}
        />
        <main>
          <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <MainFeaturedPost post={mainFeaturedPost} />
            </Grid>
          </Grid>
          {showAlert && (
            <Grid container justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
              <Stack justifyContent="center" direction="row" sx={{ width: '100%', padding: 2 }}>
                <Alert variant="filled" severity="warning" onClose={handleCloseAlert}>
                  Please allow popups to use this website's features.
                </Alert>
              </Stack>
            </Grid>
          )}
        </main>
      </Container>
      <Footer title="Created by Blake @ Data Lifesaver" description="Work in progress" />
    </ThemeProvider>
  );
}
