import React, { useState } from 'react';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import Grid from '@mui/material/Grid'; // Import the Grid component

const SearchPopover = ({
    onSearchSubmit,
    folders,
    searchValue,
    searchFolder,
    onSearchChange,
    onSearchFolderChange,
    matchType,
    onMatchTypeChange,
}) => {
    return (
        <div>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <TextField
                        value={searchValue}
                        onChange={onSearchChange}
                        label="Search"
                        variant="outlined"
                        fullWidth
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                        <InputLabel id="search-folder-label">Search Folder</InputLabel>
                        <Select
                            labelId="search-folder-label"
                            id="search-folder-select"
                            value={searchFolder}
                            onChange={onSearchFolderChange}
                            label="Search Folder"
                        >
                            <MenuItem value="All">All</MenuItem>
                            {folders.map((folder) => (
                                <MenuItem key={folder.id} value={folder.id}>
                                    {folder.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                        <InputLabel id="match-type-label">Match Type</InputLabel>
                        <Select
                            labelId="match-type-label"
                            id="match-type-select"
                            value={matchType}
                            onChange={onMatchTypeChange}
                            label="Match Type"
                        >
                            <MenuItem value="contains">Contains</MenuItem>
                            <MenuItem value="exact">Exact</MenuItem>
                            <MenuItem value="startsWith">Starts With</MenuItem>
                            <MenuItem value="endsWith">Ends With</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={onSearchSubmit}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default SearchPopover;
