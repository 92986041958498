import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import MailExMaxLogoLg from './MailExMaxLogoLg.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

function Header(props) {
  const { sections, title } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <AppBar position="static" elevation={0} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={MailExMaxLogoLg} alt="MailExMax" style={{ height: '90px', marginRight: '8px' }} />
            <Typography component="h2" variant="h5" color="inherit">
              {title}
            </Typography>
          </div>
          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate('/login')}
            >
              {/* Add your menu icon here */}
            </IconButton>
          ) : (
            <Button variant="contained" size="small" onClick={() => navigate('/login')}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar
        component={Stack}
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
        variant={isMobile ? 'regular' : 'dense'}
        sx={{
          justifyContent: isMobile ? 'center' : 'flex-start',
          overflowX: 'auto',
          padding: 0,
          mt: isMobile ? 2 : 0,
        }}
      >
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
          {sections.map((section) => {
            const Component = section.component || Button;
            return (
              <Component
                key={section.title}
                color="inherit"
                variant={section.variant || 'text'}
                href={section.url}
                sx={{
                  p: 1,
                  flexShrink: 0,
                  width: isMobile ? '100%' : 'auto',
                  maxWidth: isMobile ? '100%' : 'none',
                }}
              >
                {section.title}
              </Component>
            );
          })}
        </Stack>
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      component: PropTypes.elementType,
      variant: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
