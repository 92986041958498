import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { env } from './env';

const AppSettingsPage = () => {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState('');
    const [clientId, setClientId] = useState('');
    const [tenantId, setTenantId] = useState('');
    const [redirectUri, setRedirectUri] = useState('');
    const [authDomain, setAuthDomain] = useState('');
    const [projectId, setProjectId] = useState('');
    const [storageBucket, setStorageBucket] = useState('');
    const [messagingSenderId, setMessagingSenderId] = useState('');
    const [appId, setAppId] = useState('');
    const [measurementId, setMeasurementId] = useState('');

    useEffect(() => {
        const fetchSettings = () => {
            try {
                // Read the environment variables from env.js
                setCompanyName(env.REACT_APP_COMPANY_NAME);
                setClientId(env.REACT_APP_CLIENT_ID);
                setTenantId(env.REACT_APP_TENANTID);
                setRedirectUri(env.REACT_APP_REDIRECT_URI);
                setAuthDomain(env.REACT_APP_AUTH_DOMAIN);
                setProjectId(env.REACT_APP_PROJECT_ID);
                setStorageBucket(env.REACT_APP_STORAGE_BUCKET);
                setMessagingSenderId(env.REACT_APP_MESSAGING_SENDER_ID);
                setAppId(env.REACT_APP_APP_ID);
                setMeasurementId(env.REACT_APP_MEASUREMENT_ID);
            } catch (error) {
                console.error('Error fetching settings: ', error);
            }
        };

        fetchSettings();
    }, []);

    const handleSaveSettings = (e) => {
        e.preventDefault();

        try {
            // Update the environment variables
            const updatedEnv = {
                ...env,
                REACT_APP_COMPANY_NAME: companyName,
                REACT_APP_CLIENT_ID: clientId,
                REACT_APP_TENANTID: tenantId,
                REACT_APP_REDIRECT_URI: redirectUri,
                REACT_APP_AUTH_DOMAIN: authDomain,
                REACT_APP_PROJECT_ID: projectId,
                REACT_APP_STORAGE_BUCKET: storageBucket,
                REACT_APP_MESSAGING_SENDER_ID: messagingSenderId,
                REACT_APP_APP_ID: appId,
                REACT_APP_MEASUREMENT_ID: measurementId,
            };

            // Assign the updated env object to window.env
            window.env = updatedEnv;

            console.log('Settings updated');
        } catch (error) {
            console.error('Error saving settings: ', error);
        }
    };

    const handleGoBack = () => {
        navigate('/login');
    };

    return (
        <div>
            <h1>App Settings Page</h1>
            <button onClick={handleGoBack}>Go Back</button>

            <h2>Firestore Configuration</h2>
            <form onSubmit={handleSaveSettings}>
                <label htmlFor="authDomain">Auth Domain:</label>
                <input
                    type="text"
                    id="authDomain"
                    value={authDomain}
                    onChange={(e) => setAuthDomain(e.target.value)}
                />
                <label htmlFor="projectId">Project ID:</label>
                <input
                    type="text"
                    id="projectId"
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                />
                <label htmlFor="storageBucket">Storage Bucket:</label>
                <input
                    type="text"
                    id="storageBucket"
                    value={storageBucket}
                    onChange={(e) => setStorageBucket(e.target.value)}
                />
                <label htmlFor="messagingSenderId">Messaging Sender ID:</label>
                <input
                    type="text"
                    id="messagingSenderId"
                    value={messagingSenderId}
                    onChange={(e) => setMessagingSenderId(e.target.value)}
                />
                <label htmlFor="appId">App ID:</label>
                <input
                    type="text"
                    id="appId"
                    value={appId}
                    onChange={(e) => setAppId(e.target.value)}
                />
                <label htmlFor="measurementId">Measurement ID:</label>
                <input
                    type="text"
                    id="measurementId"
                    value={measurementId}
                    onChange={(e) => setMeasurementId(e.target.value)}
                />

                <button type="submit">Save Firestore Configuration</button>
            </form>

            <h2>App Configuration</h2>
            <form onSubmit={handleSaveSettings}>
                <label htmlFor="companyName">Company Name:</label>
                <input
                    type="text"
                    id="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                />
                <label htmlFor="clientId">Client ID:</label>
                <input
                    type="text"
                    id="clientId"
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                />
                <label htmlFor="tenantId">Tenant ID:</label>
                <input
                    type="text"
                    id="tenantId"
                    value={tenantId}
                    onChange={(e) => setTenantId(e.target.value)}
                />
                <label htmlFor="redirectUri">Redirect URI:</label>
                <input
                    type="text"
                    id="redirectUri"
                    value={redirectUri}
                    onChange={(e) => setRedirectUri(e.target.value)}
                />

                <button type="submit">Save App Configuration</button>
            </form>
        </div>
    );
};

export default AppSettingsPage;
