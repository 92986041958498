import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TopBar from './TopBar';
import { Select, MenuItem, Button } from '@mui/material';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Client } from '@microsoft/microsoft-graph-client';
import { getSpaToken } from './appauthToken';
import { Link } from 'react-router-dom';

const RulesInterface = () => {
    const [rules, setRules] = useState([]);
    const [condition, setCondition] = useState('');
    const [action, setAction] = useState('');
    const [selectedAccount, setSelectedAccount] = useState('');

    const handleConditionChange = (event) => {
        setCondition(event.target.value);
    };

    const handleActionChange = (event) => {
        setAction(event.target.value);
    };

    const handleAccountChange = (event) => {
        setSelectedAccount(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // TODO: Send the new rule to the server
    };

    const handleGetRules = async () => {
        try {
            const token = await getSpaToken(); // Use the updated getSpaToken function

            if (!token) {
                console.error('Unable to obtain access token');
                return;
            }

            const options = {
                authProvider: (done) => {
                    done(null, token);
                },
            };

            const client = Client.init(options);

            const mailbox = selectedAccount; // Replace with the selected mailbox
            const response = await client.api(`/users/${mailbox}/mailFolders/inbox/messageRules`).get();
            console.log('Response:', response); // Log the response object

            const fetchedRules = response.value.map((rule) => {
                const actions = rule.actions;
                const conditions = rule.conditions;

                let action = 'Unknown';

                if (actions && actions.moveToFolder) {
                    action = 'Move to Folder: ' + actions.moveToFolder;
                } else if (actions && actions.forwardTo) {
                    action = 'Forward to: ' + actions.forwardTo;
                } else if (actions && actions.copyToFolder) {
                    action = 'Copy to Folder: ' + actions.copyToFolder;
                } else if (actions && actions.delete) {
                    action = 'Delete';
                }

                const fromAddress = conditions?.fromAddresses?.[0]?.emailAddress;
                const name = fromAddress?.name || 'Unknown';
                const address = fromAddress?.address || 'Unknown';

                return {
                    name: name,
                    address: address,
                    action: action,
                };
            });

            console.log('Fetched Rules:', fetchedRules); // Log the fetched rules
            setRules(fetchedRules);
        } catch (error) {
            console.error('Error fetching mailbox rules:', error);
        }
    };

    useEffect(() => {
        if (selectedAccount) {
            handleGetRules();
        }
    }, [selectedAccount]);

    return (
        <div>
            <TopBar />
            <h2>Rules Interface</h2>
            <Link to="/app-token-settings">
                <Button>App Token Settings</Button>
            </Link>
            <form onSubmit={handleSubmit}>
                <label>
                    Account:
                    <Select value={selectedAccount} onChange={handleAccountChange}>
                        <MenuItem value="accoouns">Accounts</MenuItem>
                        <MenuItem value="account2">Need to add CORS</MenuItem>
                        {/* Add more options as needed */}
                    </Select>
                </label>
                <br />
                <label>
                    Rule Name:
                    <input type="text" value={condition} onChange={handleConditionChange} />
                </label>
                <br />
                <label>
                    Action:
                    <input type="text" value={action} onChange={handleActionChange} />
                </label>
                <br />
                <Button type="submit">Add Rule</Button>
                <Button onClick={handleGetRules}>Get Rules</Button>
            </form>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rules.map((rule, index) => (
                            <TableRow key={index}>
                                <TableCell>{rule.name}</TableCell>
                                <TableCell>{rule.address}</TableCell>
                                <TableCell>{rule.action}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const theme = createTheme({
    // Add your custom theme configurations here
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <RulesInterface />
        </ThemeProvider>
    );
};

export default App;
