import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState('');

    useEffect(() => {
        if (accounts.length > 0) {
            const silentTokenRequest = {
                scopes: ['User.Read', 'MailboxSettings.Read'],
                account: accounts[0],
            };

            instance
                .acquireTokenSilent(silentTokenRequest)
                .then(response => {
                    setToken(response.accessToken);
                })
                .catch(error => {
                    console.error('Silent token acquisition failed, error:', error);
                    instance
                        .loginPopup(loginRequest)
                        .then(response => {
                            setToken(response.accessToken);
                        })
                        .catch(error => {
                            console.error('Popup login also failed:', error);
                        });
                });
        }
    }, [accounts, instance]);

    return (
        <AuthContext.Provider value={{ token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
