import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithRedirect, OAuthProvider } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyDtCE62vWUEJ9hnjQ2G_e-se1_l4PzEbks",
    authDomain: "accountsapp-dd7a7.firebaseapp.com",
    projectId: "accountsapp-dd7a7",
    storageBucket: "accountsapp-dd7a7.appspot.com",
    messagingSenderId: "877644654565",
    appId: "1:877644654565:web:5045b097cdcb5a3ecd710c",
    measurementId: "G-YB2X9K73BX"
};

const initializeFirestore = async () => {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const auth = getAuth(app);

    const provider = new OAuthProvider('microsoft.com');
    // Optional: Set additional custom OAuth parameters
    provider.setCustomParameters({
        // Target specific email with login hint.
        login_hint: 'user@firstadd.onmicrosoft.com'
    });

    try {
        // Check if there is a currently signed-in user
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                console.log('User is already signed in:', user);
            } else {
                // Perform the sign-in process
                await signInWithRedirect(auth, provider);
            }
        });

        console.log('Firebase authentication successful');
    } catch (error) {
        console.error('Firebase authentication failed:', error);
    }

    return firestore;
};

export default initializeFirestore;

