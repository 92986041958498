export const env = {
	REACT_APP_COMPANY_NAME: "LECB",
	REACT_APP_CLIENT_ID: "7676530a-5fc7-4458-adc0-9efa71dc881b",
	REACT_APP_TENANTID: "7312b583-0b35-4897-99fe-e2b3c8d679ee",
	REACT_APP_REDIRECT_URI: "http://localhost:3000",
	REACT_APP_AUTH_DOMAIN: "accountsapp-dd7a7.firebaseapp.com",
	REACT_APP_PROJECT_ID: "accountsapp-dd7a7",
	REACT_APP_STORAGE_BUCKET: "accountsapp-dd7a7.appspot.com",
	REACT_APP_MESSAGING_SENDER_ID: "877644654565",
	REACT_APP_APP_ID: "1:877644654565:web:5045b097cdcb5a3ecd710c",
	REACT_APP_MEASUREMENT_ID: "G-YB2X9K73BX",
};
